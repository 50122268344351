<template>
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.891 160.891">
    <defs>
      <style>
        .cls-1 {
          fill: none;
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <g transform="translate(0)">
      <g transform="translate(0 37.517)" fill="none" stroke="#000" stroke-width="10">
        <rect width="123.374" height="123.374" stroke="none" />
        <rect x="5" y="5" width="113.374" height="113.374" fill="none" />
      </g>
      <rect width="123.374" height="123.374" transform="translate(37.517 0)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconLogo",
};
</script>

<style scoped></style>
